import { Link } from "react-router-dom";

const Header = ({ pages }) => {
  const toggleMenu = () => {
    document
      .querySelector(".navigation__container")
      .classList.toggle("is-open");
  };

  return (
    <header className="header__container">
      <Link to="/" className="header__logo ff" title="De School" />
      <nav className="navigation__container">
        {pages &&
          pages.map((page, index) =>
            page.slug ? (
              <Link
                to={page.site_url.replace("/pages", "")}
                className="navigation__item ff"
                key={index}
                title={page.title}
              >
                {page.title}
              </Link>
            ) : (
              <a
                href={page.site_url}
                className="navigation__item ff"
                key={index}
                title={page.title}
              >
                {page.title}
              </a>
            )
          )}
      </nav>
      {/*<div*/}
      {/*  className="navigation__trigger js-navigation-trigger ff"*/}
      {/*  onClick={toggleMenu}*/}
      {/*>*/}
      {/*  Menu*/}
      {/*</div>*/}
        <div className="header__language ff">
            {/*<a*/}
            {/*    href="https://www.instagram.com/deschoolamsterdam/"*/}
            {/*    target="_blank"*/}
            {/*    rel="noreferrer"*/}
            {/*    className="header__language-link footer__icon-instagram"*/}
            {/*    title=""*/}
            {/*>*/}
            {/*    Instagram*/}
            {/*</a>*/}
            <ul className="navigation">
                <li><a href="https://hetarchief.deschoolamsterdam.nl/" className="redlighted">Het Archief</a></li>
                <li><a href="https://altijd.deschoolamsterdam.nl/" className="redlighted">Het Altijd</a></li>
                <li><a href="https://www.instagram.com/deschoolamsterdam/">Instagram</a></li>
            </ul>
        </div>
    </header>
  );
};

export default Header;
